<template>
  <div class="addHousing">
    <div class="addHousing_box w-1400 margin-auto">
      <div class="business_tag flexs p-l-100">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页 </el-breadcrumb-item>
          <el-breadcrumb-item>添加房源</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="addHousing_head">
        <ul class="addHousing_head_list flex">
          <template v-for="(item, index) in housingTypeList">
            <li v-if="(type == 2 && index != 0) || type == 1" class="center" @click="changeHead(item.value)" :key="index" :class="{ active: item.value == from.fang_type }">
              {{ item.label }}
            </li>
          </template>
        </ul>
        <div class="addHousing_head_main b-c-f">
          <div class="addHousing_head_title flexs p-t-30 p-b-30">
            <span>基本信息</span>
            <span>（{{ userInfo.name }}）</span>
          </div>
          <el-form :model="from" :rules="rules" ref="ruleForm" class="demo-ruleForm" label-width="160px">
            <ul class="addHousing_head_ul flex flex-wrap">
              <li>
                <el-form-item label="房源类型:" prop="fang_typess" v-if="from.fang_type == 1">
                  <el-select v-model="from.fang_typess" placeholder="请选择房源类型">
                    <el-option v-for="item in zuHousingTypeList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>

              <li>
                <el-form-item label="房源等级:" prop="fang_level">
                  <el-select v-model="from.fang_level" placeholder="请选择房源等级">
                    <el-option v-for="item in gradeList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="房源状态:" prop="fang_status">
                  <el-select v-model="from.fang_status" placeholder="请选择房源状态">
                    <el-option v-for="item in fangStatus" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="小区名称:" prop="xiaoqu_name">
                  <el-select @change="changePlot" v-model="from.xiaoqu_name" filterable placeholder="请选择">
                    <el-option v-for="item in plotList" :key="item.value" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="开盘状态:" prop="fang_pan_status">
                  <el-select v-model="from.fang_pan_status" placeholder="请选择开盘状态">
                    <el-option v-for="item in openStatus" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="楼栋号:">
                  <div class="addHousing_head_ul_ipt building flexs">
                    <el-input v-model="from.lou" placeholder="几号楼"></el-input>
                    <el-input v-model="from.dan" placeholder="几单元"></el-input>
                    <el-input v-model="from.hao" placeholder="请输入"></el-input>
                    <el-input v-model="from.orientation" placeholder="朝向"></el-input>
                  </div>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="楼层:">
                  <div class="addHousing_head_ul_ipt building flexs floor">
                    <el-input v-model="from.xiaoqu_louceng" maxlength="3" type="number" placeholder="小区楼层"></el-input>
                    <el-input v-model="from.zong_louceng" maxlength="3" type="number" placeholder="小区总楼层"></el-input>
                    <el-select v-model="from.louceng_type" placeholder="楼层区域">
                      <el-option v-for="item in loucengTypeList" :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                </el-form-item>
              </li>
              <!-- <li>
                <el-form-item label="房型:" prop="fang_xing">
                  <el-input v-model="from.fang_xing" placeholder="请输入房型"></el-input>
                </el-form-item>
              </li> -->
              <li>
                <el-form-item label="房型:">
                  <el-select v-model="from.fang_xing_copy" placeholder="请输入房型(室)">
                    <el-option v-for="item in roomList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="朝向:">
                  <el-select v-model="from.fang_chaoxiang" placeholder="请输入朝向">
                    <el-option v-for="item in orientationList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="面积:">
                  <el-input v-model="from.fang_mianji" placeholder="请输入面积"></el-input>
                </el-form-item>
              </li>
              <li>
                <!-- <el-form-item label=""></el-form-item> -->
                <el-form-item label="梯户:">
                  <el-input v-model="from.fang_tihu" placeholder="请输入梯户"></el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="房屋用途:">
                  <el-select v-model="from.fang_yongtu" placeholder="请选择房屋用途">
                    <el-option v-for="item in houseUse" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li v-if="type == 1">
                <el-form-item label="参考价格:">
                  <el-input v-model="from.can_price" placeholder="请输入参考价格"></el-input>
                </el-form-item>
              </li>
              <li v-if="type == 1">
                <el-form-item label="总价:">
                  <el-input v-model="from.zong_price" placeholder="请输入总价"></el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="附近地铁:">
                  <el-select v-model="ditie" @change="changeMetro" multiple placeholder="请选择附近地铁">
                    <el-option v-for="item in metroList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="地铁站:">
                  <el-select @change="changeDitieAhan" v-model="ditie_zhan" multiple placeholder="请选择地铁站">
                    <el-option v-for="item in subwayList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="商圈:">
                  <el-select v-model="from.shangquan" placeholder="请选择商圈">
                    <el-option v-for="item in shangquanList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <div class="addHousing_head_ul_txt m-r-20">房屋性质:</div>
                <div class="addHousing_head_ul_ipt">
                  <el-select
                    v-model="from.fang_gou"
                    placeholder="请选择房屋性质"
                  >
                    <el-option
                      v-for="item in buildingList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </li>
              <li>
                <el-form-item label="房龄:">
                  <el-select v-model="from.fang_ling" placeholder="请选择房龄">
                    <el-option v-for="item in houseAgeList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="装修:">
                  <el-select v-model="from.fang_xiu" placeholder="请选择装修">
                    <el-option v-for="item in fitmentList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="装修时间:">
                  <el-select v-model="from.fang_xiu_times" placeholder="请选择装修时间">
                    <el-option v-for="item in fitmentTimeList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="开盘时间:">
                  <el-select v-model="from.fang_kaipan_time" placeholder="请选择开盘时间">
                    <el-option v-for="item in openingList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="开盘次数:">
                  <el-select v-model="from.fang_kaipan_ci" placeholder="请选择开盘次数">
                    <el-option v-for="item in panTime" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="物业电话:">
                  <el-input v-model="from.wuye_mobile" placeholder="请输入物业电话"></el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="物业费单价:">
                  <el-input v-model="from.wuye_price" placeholder="请输入物业费单价"></el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="每月物业费:">
                  <el-input v-model="from.wuye_price_yue" placeholder="请输入每月物业费"></el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="电费单价:">
                  <el-input v-model="from.dian_price" placeholder="请输入电费单价"></el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="燃气单价:">
                  <el-input v-model="from.ranqi_price" placeholder="请输入燃气单价"></el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="暖气单价:">
                  <el-input v-model="from.nuanqi_price" placeholder="请输入燃气单价"></el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="水费单价:">
                  <el-input v-model="from.shui_price" placeholder="请输入水费单价"></el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="看房方式:">
                  <el-select v-model="from.fang_kan" placeholder="请选择看房方式">
                    <el-option v-for="item in lookWayList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="最早入住时间:">
                  <el-select v-model="from.fang_zhu" placeholder="请选择最早入住时间">
                    <el-option v-for="item in checkTimeList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="钥匙所在店铺:">
                  <el-select v-model="from.yaoshi_dian" placeholder="请选择钥匙所在店铺">
                    <el-option v-for="item in menDianList" :key="item.id" :label="item.dian_name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li v-if="this.from.fang_type != 1">
                <el-form-item label="可带小孩:">
                  <el-select v-model="from.du_hai" placeholder="请选择是否可带小孩">
                    <el-option v-for="item in whetherList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li v-if="this.from.fang_type != 1">
                <el-form-item label="可带宠物:">
                  <el-select v-model="from.du_pets" placeholder="请选择是否可带宠物">
                    <el-option v-for="item in whetherList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li v-if="this.from.fang_type != 1">
                <el-form-item label="车位:">
                  <el-select v-model="from.che_que" placeholder="请选择是否有车位">
                    <el-option v-for="item in whetherList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li v-if="this.from.fang_type != 1">
                <el-form-item label="每月车位费:">
                  <el-input v-model="from.che_price" placeholder="请输入车位费"></el-input>
                </el-form-item>
              </li>
              <li v-if="this.from.fang_type != 1">
                <el-form-item label="车位月管理费:">
                  <el-input v-model="from.che_guan_price" placeholder="请输入车位月管理费"></el-input>
                </el-form-item>
              </li>

              <li v-if="this.from.fang_type != 1">
                <el-form-item label="户口情况:">
                  <el-select v-model="from.hujko" placeholder="请选择">
                    <el-option v-for="item in registeredList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li v-if="this.from.fang_type != 1">
                <el-form-item label="购入时间:">
                  <el-select v-model="from.goumai" placeholder="请选择购入时间">
                    <el-option v-for="item in purchaseTimeList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li v-if="this.from.fang_type != 1">
                <el-form-item label="付款方式:">
                  <el-select v-model="from.price_type" placeholder="请选择付款方式">
                    <el-option v-for="item in paymentList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li v-if="this.from.fang_type != 1">
                <el-form-item label="产权情况:">
                  <el-select v-model="from.chanquan_ren" placeholder="请选择">
                    <el-option v-for="item in registeredList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li v-if="this.from.fang_type != 1">
                <el-form-item label="房屋现状:">
                  <el-select v-model="from.fang_xianzhuang" placeholder="请选择">
                    <el-option v-for="item in actualityList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li v-if="this.from.fang_type != 1">
                <el-form-item label="租期到期时间:">
                  <el-date-picker v-model="from.zuqi_times" type="date" value-format="timestamp" placeholder="租期到期时间">
                  </el-date-picker>
                </el-form-item>
              </li>
              <li v-if="this.from.fang_type != 1">
                <el-form-item label="暂缓到期时间:">
                  <el-date-picker v-model="from.zanhuan_times" type="date" value-format="timestamp" placeholder="暂缓到期时间">
                  </el-date-picker>
                </el-form-item>
              </li>

              <li>
                <el-form-item label="业主姓名:" prop="yezhu_name">
                  <el-input v-model="from.yezhu_name" placeholder="请输入业主姓名"></el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="业主电话:" prop="yezhu_mobile">
                  <el-input maxlength="11" v-model="from.yezhu_mobile" placeholder="请输入业主电话"></el-input>
                </el-form-item>
              </li>

              <li v-if="this.from.fang_type != 1">
                <el-form-item label="有无抵押:">
                  <el-select v-model="from.diya" placeholder="请选择">
                    <el-option v-for="item in pledgeList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li v-if="this.from.fang_type != 1">
                <el-form-item label="押金偿还方式:">
                  <el-select v-model="from.yajin_type" placeholder="请选择">
                    <el-option v-for="item in repayList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li v-if="this.from.fang_type != 1">
                <el-form-item label="剩余金额:">
                  <el-input v-model="from.sheng_price" placeholder="请输入剩余金额"></el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="产权人:">
                  <el-input v-model="from.fang_chanquan" placeholder="请输入产权人"></el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="产权人证件类型:">
                  <el-select v-model="from.fang_chanquan_type" placeholder="请选择产权人证件类型">
                    <el-option v-for="item in certificateList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="证件号:">
                  <el-input v-model="from.fang_chanquan_type_hao" placeholder="请输入证件号"></el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="权属证书类型:">
                  <el-select v-model="from.fang_quanshu_type" placeholder="请选择">
                    <el-option v-for="item in certificateTypeList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="权属证书号码:">
                  <el-input v-model="from.fang_quanshu_type_hao" placeholder="请输入权属证书号码"></el-input>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="房产证图片:">
                  <div class="addHousing_head_img_ul flexs flex-wrap">
                    <div class="addHousing_head_img_ul_li avatar-uploader m-r-20" v-for="(item, index) in fang_chancheng_images" :key="index">
                      <img :src="item" alt="" class="avatar" />
                      <img @click="cancelUpload('fang_chancheng_images', index)" src="../../assets/image/publice/cancel.png" class="close" alt="" />
                    </div>
                    <div class="addHousing_head_img_ul_li avatar-uploader">
                      <input type="file" @input="uploadVideoImg($event, 'fang_chancheng_images')" />
                      <i class="el-icon-plus avatar-uploader-icon"></i>
                    </div>
                  </div>
                </el-form-item>
              </li>
              <li>
                <el-form-item label=" 身份证反正面图片:">
                  <div class="addHousing_head_img_ul flexs flex-wrap">
                    <div class="addHousing_head_img_ul_li avatar-uploader m-r-20" v-for="(item, index) in identity_images" :key="index">
                      <img :src="item" alt="" class="avatar" />
                      <img @click="cancelUpload('identity_images', index)" src="../../assets/image/publice/cancel.png" class="close" alt="" />
                    </div>
                    <div class="addHousing_head_img_ul_li avatar-uploader">
                      <input type="file" @input="uploadVideoImg($event, 'identity_images')" />
                      <i class="el-icon-plus avatar-uploader-icon"></i>
                    </div>
                  </div>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="委托书图片:">
                  <div class="addHousing_head_img_ul flexs flex-wrap">
                    <div class="addHousing_head_img_ul_li avatar-uploader m-r-20" v-for="(item, index) in weituo_images" :key="index">
                      <img :src="item" alt="" class="avatar" />
                      <img @click="cancelUpload('weituo_images', index)" src="../../assets/image/publice/cancel.png" class="close" alt="" />
                    </div>
                    <div class="addHousing_head_img_ul_li avatar-uploader">
                      <input type="file" @input="uploadVideoImg($event, 'weituo_images')" />
                      <i class="el-icon-plus avatar-uploader-icon"></i>
                    </div>
                  </div>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="内外网房源:" prop="fang_type_wang">
                  <el-select v-model="from.fang_type_wang" placeholder="请选择内外网房源">
                    <el-option v-for="item in netList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item label="房屋标签:" prop="fang_biaoqian">
                  <div class="houseing_ul flexs flex-wrap">
                    <div class="houseing_ul_li center" @click="changeTag(item)" :class="{ active: item.checked }" v-for="(item, index) in houseTagList" :key="index">
                      {{ item.name }}
                    </div>
                  </div>
                </el-form-item>
              </li>
            </ul>
          </el-form>
          <div class="houseSelect m-t-30">
            <div class="houseSelect_txt m-b-20">户型选择</div>
            <ul class="houseSelect_ul flex flex-wrap">
              <li @click="selectHuxing(item)" v-for="(item, index) in houseSelectList" :key="index">
                <div class="houseSelect_ul_select">
                  <img src="../../assets/image/houseing/select_pink.png" v-if="item.checked" alt="" />
                  <img src="../../assets/image/houseing/select.png" alt="" v-else />
                </div>
                <div class="houseSelect_img">
                  <img :src="item.image" alt="" />
                </div>
                <div class="houseSelect_mian">
                  <div class="houseSelect_name">{{ item.huxing }}</div>
                  <div class="houseSelect_area">
                    面积{{ item.mianji }}m²/{{ item.chaoxiang }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="houseSelect m-t-30">
            <div class="houseSelect_txt m-b-30 m-t-20">房源简介</div>
            <div class="houseSelect_rich">
              <div id="rich"></div>
            </div>
          </div>
          <div class="addHousing_head_main_btn center">
            <el-button @click="addHousing('ruleForm')">保存</el-button>
          </div>
        </div>
        <div class="addHousing_head_main m-t-20 b-c-f" v-if="houseId">
          <div class="addHousing_head_title flexs p-t-30 p-b-30">
            <span>图片</span>
            <span>（{{ userInfo.name }}）</span>
          </div>
          <ul class="addHousing_head_ul flex flex-wrap">
            <ul class="addHousing_head_ul addHousing_head_img addHousing_head_uls">
              <li>
                <div class="addHousing_head_ul_txt m-r-20">封面图:</div>
                <div class="addHousing_head_img_ul">
                  <el-upload class="avatar-uploaders" :show-file-list="false" action="https://xin.xinyoujudichan.com/api/common/upload" list-type="picture-card" :on-success="uploadCoverImg">
                    <img v-if="from.fang_fengmian_image" :src="from.fang_fengmian_image" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </div>
              </li>
              <li>
                <div class="addHousing_head_ul_txt m-r-20">轮播图:</div>
                <div class="addHousing_head_img_ul flexs flex-wrap">
                  <div class="addHousing_head_img_ul_li avatar-uploader m-r-20" v-for="(item, index) in fang_lunbo_images" :key="index">
                    <img :src="item" alt="" class="avatar" />
                    <img @click="cancelUpload('fang_lunbo_images', index)" src="../../assets/image/publice/cancel.png" class="close" alt="" />
                  </div>
                  <div class="addHousing_head_img_ul_li avatar-uploader">
                    <input type="file" @input="uploadVideoImg($event, 'fang_lunbo_images')" />
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                  </div>
                </div>
              </li>
              <li>
                <div class="addHousing_head_ul_txt m-r-20">视频:</div>
                <div class="addHousing_head_img_ul flexs flex-wrap">
                  <div class="addHousing_head_img_ul_li avatar-uploader m-r-20" v-for="(item, index) in fang_vediofile" :key="index">
                    <video :src="item"></video>
                    <img @click="cancelUpload('fang_vediofile', index)" src="../../assets/image/publice/cancel.png" class="close" alt="" />
                  </div>
                  <div class="addHousing_head_img_ul_li avatar-uploader">
                    <input type="file" @input="uploadVideoImg($event, 'fang_vediofile')" />
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                  </div>
                </div>
              </li>
            </ul>
          </ul>
          <div class="addHousing_head_main_btn center">
            <el-button @click="saveImg">保存</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import index from "../../common/housing";
import { regionData, CodeToText } from "element-china-area-data";
export default {
  mixins: [index],
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["userInfo", "region"]),
  },
  data () {
    return {
      houseId: null, //房源id
      regionList: regionData,
      CodeToText: CodeToText,
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
      ],
      value: "",
      input: "",
      type: null, //1出售2出租
      areaList: [], //区域列表
      from: {
        fang_typess: "", //2=住宅3=公寓4=写字楼5=商铺6=别墅
        fang_type: 1, //1=新房2=住宅3=公寓4=写字楼5=商铺6=别墅
        fang_level: "", //房源等级 A/B/C/D/展位
        fang_status: "", //1未交易3有效暂缓5无效暂缓7我司成交9他司成交
        fang_city: "", //区域
        fang_pan_status: "", //开盘状态 未开盘/已开盘
        fang_address: "", //详细地址
        xiaoqu_id: "", //小区id
        xiaoqu_name: "", //小区名字
        xiaoqu_developers: "", //开发商
        xiaoqu_loudong: "", //小区楼栋号 （用逗号隔开）
        xiaoqu_louceng: "", //小区楼层
        zong_louceng: "", //小区总楼层
        lou: "", //几号楼
        dan: "", //几单元
        hao: "", //几号
        shangquan: "", //商圈
        orientation: "", //朝向
        louceng_type: "", //楼层区域（低/中/高/顶）
        fang_xing: "", //型
        fang_xing_copy: "", //房型（室 直接传数字）
        fang_chaoxiang: "", //朝向
        fang_mianji: "", //面积（平方）
        fang_tihu: "", //梯户
        can_price: "", //参考价
        price_type: "", //付款方式
        zu_time: "", //租期
        zu_type: "", //租赁方式
        zong_price: "", //总价
        ditie: "", //附近地铁 (多条线用逗号隔开
        ditie_zhan: "", //地铁站 (多站用逗号隔开
        fang_yongtu: "", //房屋用途 居住/办公/仓库
        fang_ling: "", //房龄
        fang_gou: "", //房屋性质 
        fang_xiu: "", //装修 毛坯/简单装修/居家装修/精装修/豪华装修
        fang_xiu_times: "", //装修时间
        fang_xiu_time: "", //装修时间 时间戳
        fang_kaipan_time: "", //开盘时间 输入数字
        fang_kaipan_ci: "", //开盘次数 首开/加推
        fang_jiaos: "", //小区交房时间
        fang_jiao: "", //小区交房时间时间戳
        hujko: "", //户口情况
        goumai: "", //购入时间
        chanquan_ren: "", //产权情况
        fang_xianzhuang: "", //房屋现状
        zuqi_time: "", //租期到期时间
        zuqi_times: "", //租期到期时间 时间戳
        zanhuan_time: "", //暂缓时间
        zanhuan_times: "", //暂缓时间 时间戳
        diya: "", //有无抵押
        yajin_type: "", //偿还方式
        sheng_price: "", //剩余金额
        wuye: "", //小区物业
        wuye_mobile: "", //物业电话
        wuye_price: "", //物业费单价
        wuye_price_yue: "", //物业费月
        dian_price: "", //电费
        ranqi_price: "", //燃气费
        nuanqi_price: "", //暖气费
        shui_price: "", //水费
        che_que: "",
        che_price: "", //车位费每月
        che_guan_price: "", //车位管理费
        xinxi_qudao: "", //信息来源id
        fang_kan: "", //看房方式 我司有钥匙/借钥匙/业主开门
        fang_zhu: "", //最早入住时间 输入时间戳
        yaoshi_dian: "", //钥匙所在店铺id
        du_hai: "", //是否可带小孩
        du_pets: "", //是否可带宠物
        yezhu_name: "", //业主姓名
        yezhu_mobile: "", //业主电话
        fang_type_wang: "", //内外网房源 内网/外网
        fang_chanquan: "", //产权人
        fang_chanquan_type: "", //产权人证件 身份证/房产证
        fang_chanquan_type_hao: "", //产权人证件号
        fang_quanshu_type: "", //权属证书
        fang_quanshu_type_hao: "", //权属证书号
        fang_chancheng_images: "", //房产证图（多张用逗号隔开）,
        identity_images: "", //身份证图
        weituo_images: "", //委托书图
        fang_biaoqian: "", //房屋标签（多个用逗号隔开）
        fang_huxing: "", //户型id
        fang_content: "", //房源简介
        fang_jingwei: "", //经纬度
        fang_fengmian_image: "",
      },
      rules: {
        fang_typess: [
          { required: true, message: "请选择房源类型", trigger: "change" },
        ],
        fang_address: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
        fang_level: [
          { required: true, message: "请选择房源等级", trigger: "change" },
        ],
        fang_status: [
          { required: true, message: "请选择房源状态", trigger: "change" },
        ],
        fang_city: [
          { required: true, message: "请选择所在区域", trigger: "change" },
        ],
        xiaoqu_name: [
          { required: true, message: "请选择小区名称", trigger: "change" },
        ],
        fang_pan_status: [
          { required: true, message: "请选择开盘状态", trigger: "change" },
        ],
        xinxi_qudao: [
          { required: true, message: "请选择信息来源", trigger: "change" },
        ],
        date1: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        date2: [
          {
            type: "date",
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        fang_biaoqian: [
          { required: true, message: "请选择房屋标签", trigger: "change" },
        ],
        fang_type_wang: [
          { required: true, message: "请选择内外网房源", trigger: "change" },
        ],
        fang_xing: [{ required: true, message: "请输入房型", trigger: "blur" }],
        yezhu_name: [
          { required: true, message: "请输入业主姓名", trigger: "blur" },
        ],
        yezhu_mobile: [
          { required: true, message: "请输入业主电话", trigger: "blur" },
        ],
      },
      ditie: [], //附近地铁
      ditie_zhan: [], //地铁站
      fang_chancheng_images: [], //房产证图片
      identity_images: [], //身份证
      weituo_images: [], //委托书图片
      fang_fengmian_image: [], //封面图
      fang_lunbo_images: [], //轮播图
      fang_vediofile: [], //视频
      shangquanList: [], //商圈列表
    };
  },
  methods: {
    selectHuxing (item) {
      item.checked = !item.checked;
      this.$forceUpdate();
    },
    //保存图片
    saveImg () {
      if (!this.from.fang_fengmian_image)
        return this.$message({ message: "请上传封面图", type: "warning" });
      if (!this.fang_lunbo_images.length)
        return this.$message({ message: "请上传轮播图", type: "warning" });

      this.$axios[this.type == 1 ? "houseShouAddImages" : "houseZuAddImages"]({
        id: this.houseId,
        fang_fengmian_image: this.from.fang_fengmian_image,
        fang_lunbo_images: this.fang_lunbo_images.join(","),
        fang_vediofile: this.fang_vediofile.join(","),
      }).then((res) => {
        this.$message({ message: res.msg, type: "success" });
        this.$router.go(-1);
      });
    },
    //添加出售房源
    addHousing (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.from.xiaoqu_loudong =
            this.from.lou +
            (this.from.dan ? "," + this.from.dan : "") +
            (this.from.hao ? "," + this.from.hao : "") +
            (this.from.orientation ? "," + this.from.orientation : "");

          if (this.fang_chancheng_images.length) {
            this.from.fang_chancheng_images =
              this.fang_chancheng_images.join(",");
          }
          if (this.identity_images.length) {
            this.from.identity_images = this.identity_images.join(",");
          }
          if (this.weituo_images.length) {
            this.from.weituo_images = this.weituo_images.join(",");
          }
          this.from.fang_content = this.editor.txt.html();
          let huxing = [];
          this.houseSelectList.forEach((item) => {
            if (item.checked) {
              huxing.push(item.id);
            }
          });
          this.from.fang_huxing = huxing.join(",");
          this.from.zanhuan_times
            ? (this.from.zanhuan_time = this.from.zanhuan_times / 1000)
            : "";
          this.from.zuqi_times
            ? (this.from.zuqi_time = this.from.zuqi_times / 1000)
            : "";
          // this.from.ditie = this.ditie.join(",");
          // this.from.ditie_zhan = this.ditie_zhan.join(",");
          this.$axios[this.type == 1 ? "houseShouAdd" : "houseZuAdd"](
            this.from
          ).then((res) => {
            this.houseId = res.data;
            this.$message({ message: res.msg, type: "success" });
          });
        } else {
          return false;
        }
      });
    },
    changeHead (value) {
      this.from.fang_type = value;
    },
  },
  created () {
    this.type = this.$route.query.type;
    if (this.type == 2) this.from.fang_type = 2;
  },
};
</script>

<style lang="less" scoped>
.addHousing_head_list {
  padding: 0 225px;
  background: #3273f6;
  li {
    cursor: pointer;
    height: 64px;
    color: #ebebeb;
    font-size: 18px;
  }
  .active {
    color: #ffffff;
    font-weight: bold;
    position: relative;
    &::before {
      content: "";
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      width: 24px;
      height: 2px;
      background: #ffffff;
    }
  }
}
.addHousing_head_title {
  padding-left: 100px;
  span {
    &:first-child {
      font-size: 18px;
      padding-left: 14px;
      position: relative;
      &::before {
        content: "";
        width: 4px;
        height: 20px;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
        background: #3273f6;
        position: absolute;
      }
    }
    &:last-child {
      color: #3273f6;
      font-size: 16px;
    }
  }
}
.addHousing_head_ul {
  padding: 0 100px;
  li {
    width: 50%;
    display: flex;
    align-items: center;
    // margin-bottom: 20px;
    .addHousing_head_ul_txt {
      width: 140px;
      color: #666666;
      font-size: 16px;
      text-align: right;
    }
    /deep/ .el-input__inner {
      width: 400px;
      height: 40px;
      border: 1px solid #cccccc;
    }

    .building {
      /deep/ .el-input__inner {
        width: 96px;
        margin-right: 5px;
      }
    }
    .floor {
      /deep/ .el-input__inner {
        width: 130px;
      }
    }
    .time {
      /deep/ .el-input {
        width: 400px !important;
      }
    }
    .houseing_ul_li {
      height: 28px;
      color: #666666;
      padding: 0 10px;
      font-size: 14px;
      cursor: pointer;
      background: #ebebeb;
      border-radius: 3px;
      margin: 0 10px 10px 0;
    }
    .active {
      color: #3273f6;
      background: #e1ebff;
    }
  }
}
.addHousing_head_uls {
  padding: 0;
  li {
    width: 100%;
    .addHousing_head_ul_txt {
      width: 60px !important;
    }
  }
}
.addHousing_head_img,
.addHousing_head_ul {
  align-items: flex-start;
  li {
    align-items: flex-start;
    .addHousing_head_img_ul {
      /deep/ .el-upload--picture-card {
        width: 116px;
        height: 116px;
        line-height: 116px;
      }
      /deep/ .el-upload-list__item {
        width: 116px;
        height: 116px;
      }
      .addHousing_head_img_ul_li {
        position: relative;
        margin-bottom: 10px;
        .close {
          top: -12px;
          right: -12px;
          width: 24px;
          height: 24px;
          cursor: pointer;
          position: absolute;
        }
        input {
          width: 100%;
          height: 100%;
          display: block;
          opacity: 0;
          cursor: pointer;
          position: absolute;
          border-radius: 6px;
        }
      }
    }
  }
}
.required {
  &::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
}
.houseSelect {
  padding: 0 100px;
  .houseSelect_txt {
    // width: 140px;
    color: #3273f6;
    font-size: 16px;
    // text-align: right;
  }
  .houseSelect_ul {
    li {
      display: flex;
      width: 580px;
      padding: 20px;
      margin-bottom: 20px;
      background: #ffffff;
      box-shadow: 0px 0px 6px 0px rgba(189, 189, 189, 0.16);
      border-radius: 4px;
      position: relative;
      .houseSelect_ul_select {
        top: 20px;
        right: 20px;
        position: absolute;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
      .houseSelect_img {
        img {
          width: 120px;
          height: 90px;
        }
        margin-right: 10px;
      }
      .houseSelect_mian {
        height: 90px;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
      }
      .houseSelect_name {
        font-size: 20px;
      }
      .houseSelect_area {
        color: #666666;
        font-size: 18px;
      }
    }
  }
  .houseSelect_rich {
    background: #fafafa;
    border-radius: 8px;
    padding: 20px;
  }
}
.addHousing_head_main_btn {
  padding: 60px 0;
  /deep/ .el-button {
    width: 680px;
    height: 56px;
    color: #ffffff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 8px;
  }
}
.fitment_time {
  /deep/ .el-input {
    width: 400px;
  }
}
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/ input[type="number"] {
  -moz-appearance: textfield !important;
}
.avatar-uploaders /deep/ .el-upload,
.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  width: 118px;
  height: 118px;
  video {
    width: 100%;
    height: 100%;
    display: block;
  }
}
.avatar-uploader /deep/ .el-upload:hover,
.avatar-uploader:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 116px;
  height: 116px;
  line-height: 116px;
  text-align: center;
  display: block;
  background-color: #fbfdff;
}
.avatar {
  width: 116px;
  height: 116px;
  display: block;
}
</style>
